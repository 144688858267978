import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
  List,
  ListItem,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  gray,
  lightGray,
  yellow,
  white,
  black,
  lightLightGray,
} from "./functions/Colors";

const TOSPage = ({ height, width }) => {
  const tabNames = [
    "Commission Info",
    "Artwork Features",
    "Sales and Discounts",
  ];

  const imageHeight = width / 5;

  const TOSInfo = {
    "Commission Info": [
      [
        "1/6 How to Order?",
        [
          "If you want to order a commission from me, text me either on my Discord (preferably) or Telegram. \n \n",
	  "Ordering from me requires you to be over 18 years old, since most of my artwork is NSFW.",
          "I have no way of verify that, so by commissioning from me, I take no further responsibility in this regard. \n \n",
          "When texting me, just say hi and ask if I'm open for commissions. I will ask for the rest.",
          "I have full right declining your commission, and any steps during the commission that I would personally see as hurtful to the quality of my work, as I post every single artwork I make.",
          "This ofcourse is on both sides to consultate. I'm always open for any imput during the art process. I myself will do my best to deliver the best of me on any commission. ",
        ],
      ],
      [
        "2/6 Payment",
        [
          "If you are ordering from me for the first time, payment is paid all upfront, or split in half, first half being paid before the process of sketching starts, and second half after the sketch is done. Paypal takes a lot of fees, so all upfront is prefered, but not mandatory.",
          "For returning customers, commission is paid in full anytime, but max until end of sketch phase.",
          "Exception being animations. Those are always 50/50, but instead, the second half is paid after I animate the sketch. \n \n",
          "I accept payments via PayPal only. I will provide a payment link after sketch phase in finnished.",
          "Payment and all prices listed are in US Dollars. \n \n",
          "If you are unsure about the amount from converting currency is correct, please feel free to double check it with me before payment.",
        ],
      ],
      [
        "3/6 Art Usage",
        [
          "After art is finnished, I will provide two versions of the art, personal and public. \n \n",
          "Personal, as name suggests, is for personal use. It's version with no watermark and full resolution.",
          "You are not allowed to publicly share Personal art, only among friends and such. \n \n",
          "Personal art will be then shared on my Patreon. This is why I'm against its public sharing.",
          "Public version is for public sharing. Do whatever you please with it, except removing the watermark.",
          "If you share my art on public site, I will ask you to link this site to its description.",
          "The public image is yours to post where you please, however I do like to ask that you wait until I post it publicly to sites like FA and E621 \n \n",
          "If you wish to order personal commission, be sure to read Bonus Content tile.",
          "If there are multiple versions of the artwork, my preferation is that shared publicly will only be the base version. This is further consultable and may be subject to change.",
        ],
      ],
      [
        "4/6 Turnaround time",
        [
          "Below turnaround time is based on most commission I did.",
          "If I cannot meet the turnaround time, I will keep you notified. \n \n",
          "Sketch is done and shown within 2-5 bussiness days.",
          "Lineart is done and shown within next 2-4 weeks.",
          "Coloring is done and shown within next 2 business days.",
          "Shading is done and shown within next 2-4 business days. \n \n",
          "All of this is up to trippled for animation comissions and extended a bit if the artwork has a background",
        ],
      ],
      [
        "5/6 Ref. sheet Bundles",
        [
          "Small Reference Sheet",
	  "160 dollars",
          "Front and Back view, headshot, crotch.",
          <Image src="./example_images/1.webp" height={imageHeight} />,
          "OR \n Front and Back view, simple clothing, SFW version.",
          <Image src="./example_images/2.webp" height={imageHeight} />,
          "\n \n Mid Reference Sheet",
	  "210 dollars",
          "Front and Back view, headshot, two crotches / private part area closeup, simple or mediocre clothing, SFW version.",
          <Image src="./example_images/3.webp" height={imageHeight} />,
	  "\n \n Big Reference Sheet",
	  "260 dollars",
          "Front and Back view (different poses), lewd fullbody pose, headshot, two crotches / pricate part area closeup, Clothing (Options: Difficult clothing / Regular + Underwear clothes / Regular SFW+NSFW clothes), SFW version",
          <Image src="./example_images/4.webp" height={imageHeight} />,
	  "\n \n Artistic Reference",
          "300 dollars",
	  "Same amount of stuff as Big Reference Sheet, but the entire reference sheet design is much more detailed and trully shows off what your character is about!",
	  <Image src="./example_images/13.webp" height={imageHeight} />,
	  "\n \n Bundles are set in stone, so no add-ons for bundles! ",
        ],
      ],
      [
        "6/6 New Character Creation",
        [
          "If we are designing your character from scratch, the price for brainstorming and designing is 10$ and added to final price. \n (This most often happens when creating a reference sheet.)",
          "You can be assured I will do my best to create character you will like, offer my own ideas, and test design stuff like hair styles, body type etc. colors and marks, until you are satisfied with the visuals!",
        ],
      ],
    ],

    "Artwork Features": [
      [
        "1/5 Kinks",
        [
	  "If your commission embraces any specific kink, an addon is added to the final price. \n \n ",
          "Common Kink \n 5% of commission price, but minimally 5$ \n Examples: \n soft bdsm, paws, chubby/fat, pregnancy, soft gore, tentacles \n \n ",
          "Uncommon Kink \n 10% of commission price, but minimally 10$ \n Examples: \n rough bdsm, water sports, diapers, inflation, eggnation... \n \n ",
          "Extreme Kink \n 50% of commission price, but minimally 25$ \n One example to rule them all: Vore \n \n ",
          "If your commission contains more kinks, like two Common and one Uncommon, their price counts together.",
          'Kink means expression of some feature. Meaning I wont count "chubby" as a kink if your char is chubby. I will count it if its expressed, like with a belly play.',
          '\n \n Latex artwork has to be always be in "Simple shade" style.',
        ],
      ],
      [
        "2/5 Character Designs",
        [
          "If your created character has any of special properties listed here, an extra addon is added to final price.",
          "Prices are shown in columns for each of my style.",
	  "ADDITION TYPE  |  FLAT  |  SHADE \n \n",
          "Simple addition | +5$  |  +10$",
          "Ex.: Large Antlers, Hyper genitalia, Obese body, Usual body features... \n \n ",
          "Larger addition | +10$  |  +18$",
          "Ex.: Wings, Naga character, Hyper body, Unusual body features... \n \n ",
          "Difficult addiion | +15$  |   +25$ ",
          "Ex.: Taur character, Difficult body assets, Unusual body features, Multiple body parts... ",
        ],
      ],
      [
        "3/5 Background",
        [
          "One colored or shape-like backgrounds costs nothing extra. \n \n ",
          "Price of background is not exactly defined, however, I have some quality standards, hence I will not accept a background addon if the quality of it would affect the overall piece quality. Trust me, flaked background can ruin art pieces that look good. \n \n ",
          "Decent quality background costs 60 dollars for Flat color and 85 for simple shade. For detailed background expect double - tripple the price. \n Examples of usual vs detailed background:",
          <Image src="./example_images/6.webp" height={imageHeight} />,
	  <Image src="./example_images/7.webp" height={imageHeight} />,
        ],
      ],
      [
        "4/5 Extra versions",
        [
          "If you wish to have more versions of the same image, here are extra costs depending on what it is.",
          "Prices are shown in columns for each of my style, and apply for every single body affected. FLAT | SHADE. \n SFW (Females):  +8$  |   +12$ \n SFW (Males):  +10$  |  +15$ \n CUM Version:  +10$  |  +15$ \n Excessive Cum:  +25$  |  +35$",
          "Bonus clothing, position & expression versions are decided based on complexity and will be discussed individually.",
        ],
      ],
      [
        "5/5 Personal Commissions",
        [
          "If you wish your image is not shared publicly on my Furaffinity or E621, fee is equal to 20% of total commission price.",
          "If you wish your image is not even posted on my Patreon, fee is equal to 30% of total commission price instead.",
        ],
      ],    
    ],


    "Sales and Discounts": [
      [
        "1/7 Multiple characters",
        [
          "Every other character after the first one costs 80% of the original base price.",
          "No sale is applied to additional costs for complex designs, extra versions etc... \n \n",
          "Example, second char in flat style is peggy. Their price is 48$ instead of 60$, but still with +5$ for peggy kink. Total of 53$",
        ],
      ],
      [
        "2/7 Background characters",
        [
          "Every un-named, background crowd character (mostly for a feel of public backgrounds) costs only 60% of the basic character price.",
          "Example:",
          <Image src="./example_images/8.webp" height={imageHeight} />,
	],
      ],
      [
        "3/7 Returning Customers",
        [
          "If you ordered an artwork from me at least once before, first commission of the month comes with a 15% discount.",
          "Discount is applied at the end of the price count with all of its assets.",
        ],
      ],
      [
        "4/7 Patreon",
        [
          'Patreon at a tier of "V.I.P. Yiffer" gains 5% discount',
          'Returning customer at a Patreon tier of "V.I.P. Yiffer" gain 20% discount instead. ',
          "Discount is applied at the end of the price count with all of its assets.",
        ],
      ],
      [
        "5/7 Artistic freedom",
        [
          "If you want to save some money on a solo character commission, you can instead offer your OC for me to draw them how I see fit.",
          "You are free to add input (e.g.: preferred kinks, background,), but I still have the final say. \n \n ",
          "Work in progress updates will be less frequent and used purely for color and detail corrections.",
          "For this type of commission, money is paid up front in full before sketch phase. \n \n ",
          "Total price of the commission is reduced by 20%, 30% instead for returning customers. (25%/35% reduction for Patreons from category above) ",
        ],
      ],
      [
        "6/7 MY OCs",
        [
          "If your art includes any of my OC's, cost of that character is decreased by 25% \n \n ",
          "List of my character is as following:",
          "- Ruby:",
	  <Image src="./example_images/10.webp" height={imageHeight} />,
          " \n \n - Lucy:",
	  <Image src="./example_images/11.webp" height={imageHeight} />,
          " \n \n - Zoe: Alternative, slim and non-matured, rastafarian, deer female.",
          " \n \n - Nimeria:",
          <Image src="./example_images/13.webp" height={imageHeight} />,
          "- Saryn: Cute, thick, very tiny, childish, squirell female. ",
          "- Corrin: Muscular, tall, charming with thick dick, teacher, lynx male. ",
	  " \n \n - Rammy:",
          <Image src="./example_images/12.webp" height={imageHeight} />,
          " \n \n - Alex (My personal OC): ",
	  <Image src="./example_images/9.webp" height={imageHeight} />,
          " \n \n (Reference sheets for my remaining characters are in a making... :3)",
        ],
      ],
      [
        "7/7 Black Friday ",
        [
          "On Black Friday, 29th of November, any of my commissions come with a base 20% discount.",          
          "Returing customers get 30% instead, Patreoners 25%, and Returning Patreoners 35%. ",
          "Discount is applied at the end of the price count with all of its assets. ",
          "Minimal order is 200 dollars.",
	 ],
      ],
    ],
  };

  const [selectedTab, setSelectedTab] = useState("Commission Info");

  const handleTabChange = (index) => {
    setSelectedTab(tabNames[index]);
  };

  return (
    <Box h={height} w={width} opacity={1}>
      <Box position="relative" h="20%" w="100%">
        <Tabs
          isFitted
          variant="unstyled"
          color={lightGray}
          textColor={lightLightGray}
          size={`${height}px`}
          index={tabNames.indexOf(selectedTab)}
          onChange={handleTabChange}
        >
          <TabList>
            {tabNames.map((tab) => (
              <Tab
                _selected={{ textColor: white }}
                bgColor={black}
                _hover={{ bgColor: gray }}
                fontSize={`${height / 20}`}
                fontFamily="LonelyCake"
                textAlign="center"
              >
                {tab}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>
      <Box
        position="relative"
        h="80%"
        w="100%"
        maxH={height * 0.8}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar
          },
          "&::-webkit-scrollbar-track": {
            background: gray, // Background of the track
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888", // Color of the scrollbar thumb
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555", // Darker color on hover
          },
        }}
      >
        <Stack w="80%" position="absolute" left={width / 10}>
          {selectedTab && (
            <Accordion allowToggle>
              {TOSInfo[selectedTab].map((item, index) => (
                <AccordionItem
                  key={index}
                  value={`item-${index}`}
                  border="none"
                  color={white}
                  fontFamily="LonelyCake"
                >
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left" fontSize={height / 25}>
                        {item[0]}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <List spacing={2} align="center">
                      {item[1].map((point, pointIndex) => (
                        <ListItem key={pointIndex}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <Text
                              as="span"
                              whiteSpace="pre-line"
                              color={lightLightGray}
                              fontSize={height / 30}
                            >
                              {point}
                            </Text>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default TOSPage;
